import React from "react"
import styled from "styled-components"

import JobItem from "../../JobFair/JobItem"
import useGetWindowWidth from "../../Navbar/utils/useGetWindowWidth"

const Styles = styled.div`
  margin: auto;
`

const BREAKPOINT = 1060

export default function PerusahaanJobs({ perusahaan }) {
  const windowWidth = useGetWindowWidth()
  const isMobile = windowWidth < BREAKPOINT

  const jobs = perusahaan.job_opening
  return (
    <Styles>
      {!jobs ? (
        <h2 style={{ textAlign: "center", marginTop: "3rem" }}>
          No job openings to show
        </h2>
      ) : (
        jobs.map(({ slug, nama, employmentType, lokasi, backendId }, index) => (
          <div key={index} className="one-job">
            <JobItem
              companyName={perusahaan.nama}
              icon={perusahaan.logo.fluid ? perusahaan.logo.fluid.src : ""}
              jobTitle={nama}
              employmentType={employmentType}
              location={lokasi}
              category={perusahaan.kategori}
              key={index}
              isMobile={isMobile}
              slug={slug}
              id={backendId}
            />
          </div>
        ))
      )}
    </Styles>
  )
}
