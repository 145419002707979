import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DetailPerusahaan from "../components/DetailPerusahaan"
import PerusahaanJobs from "../components/DetailPerusahaan/PerusahaanJobs"

export default class PerusahaanJobFairTemplate extends Component {
  render() {
    const { data } = this.props

    const perusahaan = data.contentfulPerusahaan

    return (
      <Layout>
        <SEO title={perusahaan.nama} />
        {perusahaan && (
          <DetailPerusahaan perusahaan={perusahaan}>
            <PerusahaanJobs perusahaan={perusahaan} />
          </DetailPerusahaan>
        )}
      </Layout>
    )
  }
}

export const query = graphql`
  query PerusahaanJobFairData($slug: String!) {
    contentfulPerusahaan(slug: { eq: $slug }) {
      slug
      nama
      logo {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      lokasi
      kategori
      website
      nomorWhatsapp
      mediaSosial {
        internal {
          content
        }
      }
      deskripsi {
        deskripsi
      }
      funfacts {
        funfacts
      }
      job_opening {
        slug
        nama
        employmentType
        lokasi
        backendId
      }
    }
  }
`
